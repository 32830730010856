import { Component } from "@angular/core"

@Component({
  selector: "wss-aaa-root",
  templateUrl: "./app.component.html"
})
export class AppComponent {
  image = {
    src: "../assets/avagate-logo.png",
    alt: "Company Logo"
  }
}
